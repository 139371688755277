import React from 'react'
import Swash from '../../components/CaseStudies/Swash'
import Poster from '../../components/CaseStudies/Swash/social.jpg'
import page from '~utils/page'
import Head from '~shared/Head'
import { NETWORK } from '~shared/Nav'

// eslint-disable-next-line import/no-unused-modules
export default page(Swash, {
    highlight: NETWORK,
    headComponent: (
        <Head
            description="The world’s first digital Data Union"
            mediaTitle="Streamr - Swash case study | Own Your Data"
            posterSize={[1920, 1080]}
            posterUrl={Poster}
            title="Streamr - Swash case study | Buy &amp; Sell Data"
        />
    ),
})
