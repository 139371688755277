import React from 'react'
import styled from 'styled-components'
import { MqDesktop } from 'streamr-ui/css/consts'
import src from './app.png'
import Figure from '~shared/Figure'
import Container from '~shared/Container'

const Wrapper = styled.div`
    background-color: #3b3e4c;
    padding-top: 64px;

    img {
        display: block;
        max-width: 100%;
    }

    @media ${MqDesktop} {
        padding-top: 192px;
    }
`

const Inner = styled.div`
    @media ${MqDesktop} {
        padding: 0 96px;
    }
`

const MainAppView = (props) => (
    <Figure {...props}>
        <Wrapper>
            <Container>
                <Inner>
                    <img src={src} alt="Swash app main view" $appear />
                </Inner>
            </Container>
        </Wrapper>
        <Figure.Caption $appear>Swash app main view</Figure.Caption>
    </Figure>
)

export default MainAppView
