import React from 'react'
import { Link } from 'streamr-ui'
import MainAppView from './MainAppView'
import graph from './graph.svg'
import graphVertical from './graph_vertical.svg'
import poster from './poster.jpg'
import Graph from '~shared/Graph'
import Layout, { titleCss, CaseStudySpacer } from '~shared/CaseStudyLayout'
import CaseStudyVideo, { Wrapper as VideoWrapper } from '~shared/CaseStudyVideo'
import CaseStudyTitle from '~shared/CaseStudyTitle'
import CaseStudyTextWrapper from '~shared/CaseStudyTextWrapper'
import CaseStudyQuote from '~shared/CaseStudyQuote'
import CaseStudyLinks from '~shared/CaseStudyLinks'
import CaseStudyReadMore from '~shared/CaseStudyReadMore'
import CaseStudyPartnerButton from '~shared/CaseStudyPartnerButton'
import urls from '~utils/urls'

const Swash = () => (
    <Layout>
        <CaseStudyTitle
            name="Swash"
            summary="The world's first digital data union"
            css={titleCss}
        />
        <VideoWrapper>
            <CaseStudyVideo study="swash" poster={poster} />
        </VideoWrapper>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                The business model of Internet 2.0 was free products, paid for by capturing user
                behaviour and personal data, often in ways users did not expect. Streamr&apos;s
                addition to the Web 3 model allows for the creation of Data Unions —&nbsp;ad hoc
                collectives of users pooling their data to in order to increase its value and then
                selling subscriptions to it.
            </p>
            <p $appear>
                A team from Turkey has built the first of these, a browser extension called Swash,
                that was first introduced at Mozfest in&nbsp;London in late 2019. Swash allows users
                to capture their browsing data, gives them tools to hide anything sensitive, and
                then send it in real-time to Streamr Network.
            </p>
            <p $appear>
                Behind the scenes, the Streamr stack works with the xDai sidechain to enable
                potentially hundreds of thousands of data providers to withdraw payments for their
                data from the single Ethereum smart contract that powers each Data Union. This was a
                world first, the first digital data union ever created.
            </p>
        </CaseStudySpacer>
        <MainAppView />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                Swash was the first iteration of Streamr&apos;s new Data Unions feature and the
                Swash team worked closely with Streamr to find and solve problems&nbsp;early.
            </p>
            <p $appear>
                In the first month since release, before the actual Data Unions feature has shipped,
                and with zero advertising, Swash has attracted around 1000 users organically,
                indicating that there is real demand for products that use Streamr&apos;s tech stack
                to build unique ways to reward users for their&nbsp;data.
            </p>
        </CaseStudySpacer>
        <CaseStudyQuote author="Ebrahim, Swash founder &amp; lead developer" $appear>
            An easy to use pub/sub network, world-class UI/UX, and a transparent and highly
            skilled&nbsp;team.
        </CaseStudyQuote>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                Under the Swash model, users install a browser extension for their chosen browser
                &mdash; Chrome, Firefox, Brave and Edge so far. Setup involves deciding which sites
                to never record data for, and how much data you&apos;re comfortable with sharing.
                Then turn Swash on and browse as normal. The data is streamed to a Data Union where
                it is available for purchase. Revenue is distributed equally and members can choose
                when to withdraw funds to their own wallets. As a Data Union Admin, Swash takes a
                small percentage of all sales.
            </p>
        </CaseStudySpacer>
        <Graph src={graph} verticalSrc={graphVertical} caption="Revenue and data flows for Swash" />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                If you would like to monetise your user data fairly, or have a great idea
                for&nbsp;a&nbsp;Data Union-powered app but you need some help, please get in
                touch,&nbsp;we&apos;d love to hear from you.
            </p>
            <p $appear>
                <CaseStudyPartnerButton />
            </p>
        </CaseStudySpacer>
        <CaseStudySpacer
            as={CaseStudyReadMore}
            techStack={
                <CaseStudyLinks title="Tech stack">
                    <Link href="https://streamr.network/discover/data-unions/">Data Unions</Link>
                    <Link href={urls.apps.hub} blank>
                        The Hub
                    </Link>
                    <Link href="https://www.xdaichain.com/" blank>
                        xDai Sidechain
                    </Link>
                    <Link href="https://streamr.network/discover/network/">Streamr Network</Link>
                </CaseStudyLinks>
            }
            readMore={
                <CaseStudyLinks title="Learn more">
                    <Link
                        href="https://www.coindesk.com/ethereum-scaling-tech-monoplasma-wants-to-let-dapps-broadcast-crypto"
                        blank
                    >
                        Coindesk article
                    </Link>
                    <Link href="https://swashapp.io/" blank>
                        Swashapp.io
                    </Link>
                    <Link
                        href="https://chrome.google.com/webstore/detail/swash/cmndjbecilbocjfkibfbifhngkdmjgog?hl=en"
                        blank
                    >
                        Swash for Chrome
                    </Link>
                    <Link href="https://addons.mozilla.org/en-US/firefox/addon/swash/" blank>
                        Swash for Firefox
                    </Link>
                </CaseStudyLinks>
            }
        />
    </Layout>
)

export default Swash
